import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Subscription, from, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { UserData } from '../models/user-data';
import { UserRoles } from '../models/user-roles';
import { IUserRoles } from '../models/user-roles.interface';
import { HelperService } from '../services/helper-service';
import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getAuth,
  getMultiFactorResolver,
} from "firebase/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _userData: UserData;
  private _userRoles: UserRoles = new UserRoles({} as IUserRoles);
  subscription = new Subscription();
  globalMFAResolver:any;
  enrollToTpSubject = new Subject<string>();
  enrollCompleteToTpSubject = new Subject<string>();
  postmfaLoginSubject = new BehaviorSubject<string>("");
  keyValSub = new BehaviorSubject<any>(null);
  getErrorMsgSubject = new BehaviorSubject<string>(null);
  userInfo:any;
  totpResp:any;
  loginFirst:boolean = false; 
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private helperService: HelperService,
    public router: Router
  ) {

    const postmfaLoginsub = this.postmfaLoginSubject.pipe(
      switchMap((val) => {
        if (val == '') {
          return of(null);
        }
        const totpFactor = this.globalMFAResolver.hints.find(hint => hint.factorId === TotpMultiFactorGenerator.FACTOR_ID);
        const otpFromAuthenticator = val;
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpFactor.uid, otpFromAuthenticator);
        return from(this.globalMFAResolver.resolveSignIn(multiFactorAssertion)).pipe(
          catchError((error) =>{
            this.getErrorMsgSubject.next(error);
            console.log('error thrown during totp verification', error);
            return of(null);
          }),
          switchMap(userCredential => {
            if (userCredential) {
              this.loginFirst = true;
              const user = userCredential['user'];
              const userJson = JSON.parse(localStorage.getItem('user'));
              if (!userJson) {
                localStorage.setItem('user', JSON.stringify(user));
              }
            }
            return of(null);
          }),
          take(1)
        );
      })
    ).subscribe();

    this.subscription.add(postmfaLoginsub);

    const enrollCompletionsub = this.enrollCompleteToTpSubject.pipe(
      switchMap((val) =>
            from(multiFactor(this.userInfo).enroll(TotpMultiFactorGenerator.assertionForEnrollment(this.totpResp, val), 'mfaDisplayName'))
            .pipe(
              catchError((error) =>{
                this.getErrorMsgSubject.next(error);
                console.log('error thrown during mfa enrollment', error);
                return of(null);
              })
            )
          ),
        ).subscribe();
    this.subscription.add(enrollCompletionsub);

    const enrollToTpsub = this.enrollToTpSubject.pipe(
      switchMap(() =>
        from(multiFactor(this.userInfo).getSession()).pipe(
          switchMap((multiFactorSession) =>
            from(TotpMultiFactorGenerator.generateSecret(multiFactorSession)).pipe(
              tap((totpResponse) => {
                console.log('TOTP RESPONSE');
                console.log(totpResponse);
                this.totpResp = totpResponse;
                const totpUri = this.totpResp.generateQrCodeUrl(
                  this.userInfo.email,
                  "Quaker Project"
                )
                this.keyValSub.next({'secret':totpResponse.secretKey, 'totpUri':totpUri});
              })))))
    ).subscribe();
    this.subscription.add(enrollToTpsub);

    const authStateChangesub = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const userJson = JSON.parse(localStorage.getItem('user'));
          if (!userJson) {
            localStorage.setItem('user', JSON.stringify(user));
          }
          this._userData = new UserData(userJson['uid'], 
                                        userJson['fullname'], 
                                        userJson['email'], 
                                        userJson['roles'], 
                                        userJson['orgGroups']);
          return this.getUser().pipe(
            map(result => ({ user, result }))
          );
        } else {
          localStorage.setItem('user', null);
          return of({ user: null, result: null });
        }
      })
    ).subscribe((data) => {
      
      const result = data['result'];
      if (result) {
      this._userRoles = result.hasOwnProperty('roles') ? new UserRoles(result['roles']) : new UserRoles({} as IUserRoles);
      this._userData.userRoles = this._userRoles;
      this._userData.fullname = result['fullname'];
      this._userData.orgGroups = result['orgGroups'];
      const user = JSON.parse(localStorage.getItem('user'));
      user['roles'] = this._userRoles;
      user['fullname'] = result['fullname'];
      user['orgGroups'] = result['orgGroups'];
      localStorage.setItem('user', JSON.stringify(user));
      if(this.loginFirst) {
        const userRolesList = Object.keys(this._userRoles).filter(i => this._userRoles[i]);
        const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
        this.router.navigate([defaultPath]);
      }
    }});
    this.subscription.add(authStateChangesub);
  }

  ngOnInit() { }

  triggerMfaVerification(val) {
    this.postmfaLoginSubject.next(val);
  }
  getSecretVal() {
    return this.keyValSub.asObservable();
  }

  getErrorMessage() {
    return this.getErrorMsgSubject.asObservable();
  }

  enrollCompletion(val) {
    this.enrollCompleteToTpSubject.next(val);
  }

  login(email: string, password: string) {
   return from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
    switchMap(userCredential => {
      //check if email verified
      if (userCredential !== null && userCredential.user.emailVerified) {
        localStorage.setItem('user', JSON.stringify(userCredential.user));
        //check if email verified user has enrolled to mfa
        if ((userCredential.user.multiFactor.enrolledFactors).length == 0) {
          this.userInfo = userCredential.user;
          this.enrollToTpSubject.next('trigger mfa enroll');
          this.logout();
          return of({ loggedIn: false, message: "please enable multi factor authentication to continue with log in" });
        }
      } else {
        localStorage.setItem('user', null);
        userCredential.user.sendEmailVerification();
        return of({ loggedIn: false, message: "First time login? An email verification link has been sent to your email. Please check your inbox or junk." });
      }
      // some user needs to verify the email for the first time login
      //execute for users logged in succesfully
      this.upsertUserData(userCredential);
      return this.getUser().pipe(
        tap(result => {
          this._userRoles = result.hasOwnProperty('roles') ? new UserRoles(result['roles']) : new UserRoles({} as IUserRoles);
          const userJson = JSON.parse(localStorage.getItem('user'));
          userJson['roles'] = this._userRoles;
          localStorage.setItem('user', JSON.stringify(userJson));
          this._userData = new UserData(userJson['uid'], result['fullname'], userJson['email'], userJson['roles'], userJson['orgGroups']);
          const userRolesList = Object.keys(this._userRoles).filter(i => this._userRoles[i]);
          const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
          this.router.navigate([defaultPath]); // traverse to default path.
        }),
  switchMap(() => {
    return of({ loggedIn: true, message: "logged in successfully." });
  }));
    }),
    catchError(error => {
      console.error('Error signing in:', error);
      if (error.code === "auth/multi-factor-auth-required") {
        const mfaResolver = getMultiFactorResolver(getAuth(), error);
        this.globalMFAResolver = mfaResolver;
      }
      return of({ loggedIn: false, message: error.message });
    }));
  }

  private upsertUserData(userCredential: firebase.auth.UserCredential) {
  }

  loadLocalStorage() {
    const user = JSON.parse(localStorage.getItem('user'));
    this._userData = user;
    if (user && !!this._userRoles) {
      this._userRoles = new UserRoles(user.roles);
      this._userData.userRoles = this._userRoles;
    }
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  get userData() {
    return this._userData;
  }

  get userRoles(): UserRoles {
    return this._userRoles;
  }

  getUser() {
    const user = (localStorage.getItem('user')) ? (JSON.parse(localStorage.getItem('user'))) : {uid: ''};
    return this.afs.collection('users').doc(user['uid']).get()
      .pipe(map(result => (result.data()) ? result.data() : {})); 
  }

  isTokenExpired() {
    // checking whether your token has expired
    const user = JSON.parse(localStorage.getItem('user'));
    const nowTs = new Date().getTime();
    return (user && (user.stsTokenManager.expirationTime > nowTs)) ? false : true;
  }
  
  private handleAuthError(error) {
    console.error(error);
  }

  logout() {
    // this.afAuth.auth.signOut()
    this.afAuth.signOut()
    .then(() => {
      localStorage.setItem('user', null);
      this.router.navigate(['login']);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
