        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <a href="https://wrappixel.com/templates/materialpro-angular-dashboard/" class=" m-r-20 hidden-sm-up" mat-raised-button color="warn">Upgrade To Pro</a> -->

<!-- gear -->
<!-- <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/settings.png" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu> -->

<!-- logout button -->
<button mat-button (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    Logout
</button>

