import firebase from 'firebase/compat/app';
import { configItem  } from '../models/config.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, switchMap, share, tap, first, take, filter, mergeAll, toArray,  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ReportItem } from '../models/report-item';
import { DataStudioConfig } from '../models/data-studio-config.model';
import { RecordIdModelConfig } from '../models/record-ids-model-item';
import { Observable } from 'rxjs';
import { stateAbbreviations } from 'src/assets/data/stateAbbreviations';
import { stateAbbreviationsV2 } from 'src/assets/data/stateAbbreviations';
import { originalInjuries } from 'src/assets/data/injuryCode';
import { UserRoles } from '../models/user-roles';
import { ChecklistItem } from '../models/checklist-item';
import { OrganizationConfig } from '../models/organization-config';

@Injectable({
    providedIn: 'root'
})


export class HelperService {
    configPath = 'config';
    claimantConfigPath = 'claimant-report';
    dataStudioConfigPath = 'data-studio';
    organizationConfigPath = 'organization';
    samplePath = 'sample';
    recordPath = 'record-ids';

    constructor(private afs: AngularFirestore,
      private currencyPipe: CurrencyPipe) {
    }

    getRecordIdsDataConfig() {
      return this.afs.collection(
        this.configPath, 
        ref => ref.orderBy('createdAt', 'desc').limit(1)
      ).get().pipe(
        take(1),
        map(m => m.docs[0].id),
        switchMap((id) => {
          return this.afs
            .collection(this.configPath)
            .doc(id)
            .collection(this.recordPath, (records) => {
              return records
            }).valueChanges();
        }),
        map(records => {records.map(configRecord =>  this.formatRecordIdsConfig(configRecord));
          return records;
        })
      );
    }

    getConfigData() {
        //get config collection records
        return this.afs.collection(
          this.configPath, 
          ref => ref.orderBy('createdAt', 'desc').limit(1)
        ).get().pipe(
          take(1),
          map(m => m.docs[0].id),
          switchMap((id) => {
            return this.afs
              .collection(this.configPath)
              .doc(id)
              .collection(this.claimantConfigPath, (records) => {
                return records
              }).valueChanges();
          }),
          map(records => {records.map(configRecord =>  this.formatReportTextTurnsConfig(configRecord));
            return records;
          })
        );
        // return venueVolatalityRef;
      }

      getDataStudioConfig() {
        return this.afs.collection(
            this.configPath,
            ref => ref.orderBy('updatedAt', 'desc').limit(1))
            .get()
            .pipe(
                take(1),
                map(m => m.docs[0].id),
                switchMap((id) => {
                    return this.afs
                        .collection(this.configPath)
                        .doc(id)
                        .collection(this.dataStudioConfigPath)
                        .get();
                    }),
                switchMap(result => result.docs.map(dataStudio => this.formatDataStudioConfig(dataStudio.data())))
            );
      }

      getOrganizationConfig() {
        return this.afs.collection(
            this.configPath,
            ref => ref.orderBy('updatedAt', 'desc').limit(1))
            .get()
            .pipe(
                take(1),
                map(m => m.docs[0].id),
                switchMap((id) => {
                    return this.afs
                        .collection(this.configPath)
                        .doc(id)
                        .collection(this.organizationConfigPath)
                        .get();
                    }),
                switchMap(result => result.docs.map(organization => this.formatOrganizationConfig(organization.data())))
            );
      }

      getMostRecentSampleDocId(): Observable<string> {
        return this.afs.collection(this.samplePath, ref => ref.orderBy('updatedAt', 'desc').limit(1))
          .get()
          .pipe(
            take(1),
            map(m => m.docs[0].id)
          );
      }

      replaceSymbolWithReportData(configdata,reportData){
        configdata[0].sentences.forEach(sentence=>{
            if(sentence.text.includes(` @ `)){
              if(this.resolveKeys(reportData,sentence.keys[0])){
                if(sentence.keys[0] == "facts.similarCaseSummary.percentageOfSimilarCases"){
                  sentence.text = sentence.text.replace(` @ `,this.resolveKeys(reportData,sentence.keys[0])*100);
                }
                else if(sentence.keys[0] == "facts.medianPaidLoss.nextUp1PaidLoss"){
                  sentence.text = sentence.text.replace(` @ `,this.currencyPipe.transform(Math.trunc(this.resolveKeys(reportData,sentence.keys[0])), 'USD','symbol', '1.0-0'));
                }
                else{
                  sentence.text = sentence.text.replace(` @ `,this.resolveKeys(reportData,sentence.keys[0]));
                }
              }
              else if(this.resolveKeys(reportData,sentence.keys[0])== null && sentence.keys[0]==`facts.lossVenue.venueType`){
                sentence.text = sentence.text.replace(` @ `,`all types of`);
              }
              else{
                sentence.text = ``;
              }
            }

            if(sentence.text.includes(` @@ `)){
              if(this.resolveKeys(reportData,sentence.keys[1])){

              if((sentence.keys[1] == "facts.similarCaseSummary.paidLoss")||(sentence.keys[1] == "facts.medianPaidLoss.currentPaidLoss")){
                sentence.text = sentence.text.replace(` @@ `,this.currencyPipe.transform(Math.trunc(this.resolveKeys(reportData,sentence.keys[1])), 'USD','symbol', '1.0-0'));
              }
              else{
                sentence.text = sentence.text.replace(` @@ `,this.resolveKeys(reportData,sentence.keys[1]));
              }
            }
            else{
              sentence.text = ``;
            }
          }

          if(sentence.text.includes(` @@@ `)) {
            if(sentence.keys[2] == "facts.similarCaseSummary.percentageOfSimilarCases") {
              if(this.resolveKeys(reportData,sentence.keys[2]) == 0.5) {
                sentence.text = sentence.text.replace(` @@@ `,` median `);
              }
              else {
                sentence.text = sentence.text.replace(` @@@ `,`${this.resolveKeys(reportData, sentence.keys[2]) * 100}% quartile `);
              }
            }
            else if(this.resolveKeys(reportData,sentence.keys[2])) {
              sentence.text = sentence.text.replace(` @@@ `,this.resolveKeys(reportData,sentence.keys[2]));
            }
            else{
              sentence.text = ``;
            }
          }
          });
        return configdata;
      }

      solveIndexOne(configSentence,reportData){
        let prediction = this.resolveKeys(reportData,configSentence.keys[0]);
        let historical = this.resolveKeys(reportData,configSentence.keys[1]);
        if (prediction && historical){
        let predictionHistoricalDifference = this.calculateDifference(prediction,historical);
        let text = this.determineText(prediction,historical);
        if(configSentence.text.includes(`[historicalPredictionDifference]`)){
            configSentence.text = configSentence.text.replace(`[historicalPredictionDifference]`,`${text}`);
        }
        return configSentence.text;
      }
      return ``;
      }

      solveIndexTwo(configSentence,reportData,claimRangeInMonths){
        let rangeX = this.resolveKeys(reportData,configSentence.keys[0]);
        let rangeY = this.resolveKeys(reportData,configSentence.keys[1]);
        if(rangeX && rangeY){ 
        let rangeDifference = this.calculateDifference(rangeX,rangeY);
        let rangeXMonths = claimRangeInMonths[1];
        let rangeYMonths = claimRangeInMonths[2];
        let rangeZMonths = claimRangeInMonths[3];
        //There is [claimAge465DaysOrBelow - claimAge300DaysOrBelow] difference in paid loss for claims closing in [range y] - [range z] range compared to closing after [range x] months.
        if(configSentence.text.includes(`[claimAge465DaysOrBelow - claimAge300DaysOrBelow]`)){
            configSentence.text = configSentence.text.replace(`[claimAge465DaysOrBelow - claimAge300DaysOrBelow]`,`${this.currencyPipe.transform(Math.trunc(rangeDifference), 'USD','symbol', '1.0-0')}`);
        }
        while(configSentence.text.includes(`[range y]`)){
          configSentence.text = configSentence.text.replace(`[range y]`,`${rangeYMonths}`);
        }
       if(configSentence.text.includes(`[range z]`)){
        configSentence.text = configSentence.text.replace(`[range z]`,`${rangeZMonths}`);
        }
        if(configSentence.text.includes(`[range x]`)){
          configSentence.text = configSentence.text.replace(`[range x]`,`${rangeXMonths}`);
        }

        return configSentence.text;
      }
      else{
        return ``;
      }
      }

      // parse the minimum prediction paid loss for the step up in reportTextTurns #8
      // eg. Alternatively, injury development to fracture major has a predicted range of $50,000.00 to $75,000.00.
      solveIndexThree(configSentence, reportData: ReportItem) {
        let sentence = '';
        const textTurn8 = reportData.reportTextTurns[8];
        const hasStepUpInjury = reportData.facts.injuryComparison.nextUp1InjuryCode;
        const hasStepUpPrediction = textTurn8.includes('$');
        if (hasStepUpInjury && hasStepUpPrediction) {
          const splits = reportData.reportTextTurns[8].split('to');
          const stepUpMinPredNumber = parseInt(splits[1].replace('.00', '').match(/\d/g).join(''), 10 );
          const stepUpMinPredDollar = this.currencyPipe.transform(stepUpMinPredNumber, 'USD','symbol', '1.0-0');
          const currentMinPredNumber = reportData.facts.predictionRange.min;
          if (stepUpMinPredNumber > currentMinPredNumber) {
            sentence = configSentence.text.replace(`[stepUpMinPrediction]`, `${stepUpMinPredDollar}`);
            configSentence.text = sentence;
          }
        }
        return sentence;
      }

      solveIndexFour(configSentence, historical, national, venueVolatality, county) {
        // let historical = this.resolveKeys(reportData,configSentence.keys[0]);
        if (national && national>0 && historical && (historical !== national) ) {
        let percentageDifference = this.calculatePercentageDifference(historical,national);
        if(configSentence.text.includes(`[nationalAndHistorical]`)){
            configSentence.text = configSentence.text.replace(`[nationalAndHistorical]`,`${percentageDifference}`);
        }
        if(configSentence.text.includes(`[toggleVenue]`)) {
          configSentence.text = configSentence.text.replace(`[toggleVenue]`,`${venueVolatality}`);
        }
        if(configSentence.text.includes(`[toggleCounty]`)) {
          configSentence.text = configSentence.text.replace(`[toggleCounty]`,`${county}`);
        }
        return configSentence.text;
        }
        else{
          return ``;
        }
      }

      solveIndexFive(configSentence,nationalVal, venueVolatality){
        if(nationalVal && nationalVal>0){
        if(configSentence.text.includes(`[nationalMedian]`)){
            configSentence.text = configSentence.text.replace(`[nationalMedian]`,`${this.currencyPipe.transform(nationalVal, 'USD','symbol', '1.0-0')}`);
        }
        if(configSentence.text.includes(`[toggleVenue]`)) {
          configSentence.text = configSentence.text.replace(`[toggleVenue]`,`${venueVolatality}`);
        }
        return configSentence.text; 
      }
      else{
        return ``;
      }
      }
      
      solveIndexNine(configSentence,reportData){
        let inexpensive = this.resolveKeys(reportData,configSentence.keys[0]);
        let typical = this.resolveKeys(reportData,configSentence.keys[1]);
        if(inexpensive && typical){
        let inexpensiveTypicalDifference = this.calculateDifference(inexpensive,typical);
        if (inexpensiveTypicalDifference==0){
            configSentence.text = configSentence.text.replace(`[inexpensiveAndTypical]`,`similar`);
        }
        else{
            configSentence.text = configSentence.text.replace(`[inexpensiveAndTypical]`,`${inexpensiveTypicalDifference} months`);
        }
        return configSentence.text;
        }
        else{
          return ``;
        }
      }

      solveIndexSeven(configSentence,reportData,lossMonths){
        let q25ClaimLength = this.resolveKeys(reportData,configSentence.keys[1]);
        if (q25ClaimLength){
        if(configSentence.text.includes(`[inside, outside]`)){
        if (lossMonths>q25ClaimLength){
          configSentence.text = configSentence.text.replace(`[inside, outside]`,`outside`);
        }
        else{
          configSentence.text = configSentence.text.replace(`[inside, outside]`,`inside`);
        }
        }
        return configSentence.text;
      }
      else{
        return ``;
      }
      }

      determineText(val1, val2) {
        if ((val1 > val2) && val1 - val2 >= 5000){
            return `currently above`;
           }
        else if ((val1 < val2) && val2 - val1 >= 5000){
            return `currently below`;
           } 
        else{
            return `similar`;   
        }
             
       }

      resolveKeys(reportItem,key){
        if (key==undefined){
          return '';
        }
        key = key.split('.');
        var current = reportItem;
        while(key.length) {
            if(typeof current !== 'object') return '';
            current = current[key.shift()];
        }
        return current;
      }

      formatReportTextTurnsConfig(sourceData){
        const reportTextTurnsItem = new configItem();
        reportTextTurnsItem.createdAt = sourceData.createdAt;
        reportTextTurnsItem.updatedAt = sourceData.updatedAt;
        reportTextTurnsItem.version = sourceData.version;
        reportTextTurnsItem.sentences = sourceData.sentences;
      }

      formatRecordIdsConfig(sourceData) {
        const recordId = new RecordIdModelConfig();
        recordId.answersCollectionId = sourceData.answersCollectionId;
        recordId.notesCollectionId = sourceData.notesCollectionId;
        recordId.questionsCollectionId = sourceData.questionsCollectionId;
        recordId.lossReserveSummariesId = sourceData.lossReserveSummariesId;
        recordId.lossReserveAllClosedClaimsSum = sourceData.lossReserveAllClosedClaimsSum;
        recordId.lossReserveAllOpenClaimsSum = sourceData.lossReserveAllOpenClaimsSum;
        recordId.mostRecentCollectionId = sourceData.mostRecentCollectionId;
        return recordId;
      }

      formatDataStudioConfig(data): DataStudioConfig {
        const dataStudioConfig = new DataStudioConfig();
        dataStudioConfig.links = data.links;
        return dataStudioConfig;
      }

      formatOrganizationConfig(data): OrganizationConfig {
        const organizationConfig = new OrganizationConfig();
        organizationConfig.alias = data.alias;
        organizationConfig.orgGroups = data.orgGroups;
        return organizationConfig;
      }

      calculateDifference(a, b) {
        return Math.abs(a - b);
      }
    
      calculatePercentageDifference(a, b) {
        return Math.round( Math.abs( (1 - Math.abs(a - b) / ( (a + b) / 2) ) ) * 100 );
      }

      capitalize(str: string): string {
        if (str) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return str;
      }

      capitalizeAll(str: string): string {
        if (str) {
          const stringList = str.split(" ");
          const words = stringList.map((word) => { 
              return word[0].toUpperCase() + word.substring(1); 
          }).join(" ");
          return words;
        }
        return str;
        
      }

      convertToDollarAmount(value: number) {
        if (value < 1000) {
            return `${this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0')}`;
        } else if (value < 1000000) {
            return `${this.currencyPipe.transform(value / 1000, 'USD', 'symbol', '1.0-0')}k`;
        } else {
            return `${this.currencyPipe.transform(value / 1000000, 'USD', 'symbol', '1.0-1')}M`;
        }
    }

      convertToStateAbbreviation(state: string): string {
        if (state) {
          const stateFull = this.capitalize(state.trim());
          const stateAbbre = stateAbbreviations[stateFull];
          return stateAbbre;
        }
        return state;
      }

      convertToStateFullName(state: string): string {
        if (state) {
          const stateAbbre = state.trim().toUpperCase();
          const stateFull = stateAbbreviationsV2[stateAbbre];
          return stateFull;
        }
        return state;
      }

      findDifferenceInMonths(date1:Date,date2:Date){
        let months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth();
        months += date2.getMonth();
        return months <= 0 ? 0 : months;
      }

      getLossDateInMonths(report: ReportItem){
        if (report.claimantInfo.lossDate) {
          let currentDate = new Date();
          let lossDate = new Date(report.claimantInfo.lossDate['seconds'] * 1000);
          return this.findDifferenceInMonths(lossDate, currentDate);
        }
        return '';
      }

      getOriginalInjuryCode(injuryCode: string): string {
        if (injuryCode) {
          const injury = injuryCode.trim().toLowerCase();
          const original = originalInjuries[injury];
          return original;
        }
        return injuryCode;
      }

      isSuperMananger(userRole: UserRoles) {
        return this.hasAnyUserRoles(userRole, ['superManager']);
      }

      isMananger(userRole: UserRoles) {
        return this.hasAnyUserRoles(userRole, ['manager']);
      }

      isAdjustor(userRole: UserRoles) {
        return this.hasAnyUserRoles(userRole, ['adjustor']);
      }

      // check if user has at least one role from required user roles
      hasAnyUserRoles(currentUserRoles: UserRoles, requiredUserRoles: Array<string>) {
        const userRoles = currentUserRoles;
        const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
        const results = userRolesList.filter(r => requiredUserRoles.includes(r));
        if (results.length > 0) {
          return true;
        } else {
          return false;
        }
      }

      // check if user has only adjustor role and no super roles
      hasAdjustorRoleOnly(userRoles: UserRoles): boolean {
        const adjustor = this.isAdjustor(userRoles);
        const hasSuperRoles = this.hasAnyUserRoles(userRoles, ['admin', 'manager', 'superManager']);
        if (adjustor && !hasSuperRoles) {
            return true;
        } else {
            return false;
        }
      }

      // check if user has only manager role and no super roles
      hasManagerRoleOnly(userRoles: UserRoles): boolean {
        const manager = this.isMananger(userRoles);
        const hasSuperRoles = this.hasAnyUserRoles(userRoles, ['admin', 'superManager']);
        if (manager && !hasSuperRoles) {
            return true;
        } else {
            return false;
        }
      }

      // check if user has only super manager role
      hasSuperManagerRoleOnly(userRoles: UserRoles): boolean {
        const superManager = this.isSuperMananger(userRoles);
        if (superManager) {
            return true;
        } else {
            return false;
        }
      }

      getDefaultReportPath(userRolesList: Array<string>): string {
        let reportPath = 'reports/sample';
        const adminOrManageRole = userRolesList.filter(r => ['admin', 'manager', 'superManager'].includes(r));
        const adjustorRole = userRolesList.filter(r => ['adjustor'].includes(r));
        const trialRole = userRolesList.filter(r => ['trial'].includes(r));
        if (adminOrManageRole.length > 0) {
            // reportPath = 'reports/call';
            reportPath = 'reports/triage/snapshots';
        } else if (adjustorRole.length > 0) {
            reportPath = 'reports/triage/snapshots';
        } else if (trialRole.length > 0) {
            reportPath = 'reports/sample';
        }        
        return reportPath;
      }

      // filter user's orgGroups or owner of claimant reports based on user role
      queryOrgGroupsByUserRole(query: firebase.firestore.Query, userRole: UserRoles, orgGroups: Array<string>, userUid: string): firebase.firestore.Query {
        const superManager = this.hasAnyUserRoles(userRole, ['superManager']);
        const manager = this.hasAnyUserRoles(userRole, ['manager']);
        const adjustor = this.hasAnyUserRoles(userRole, ['adjustor']);
        const userOrgGroups = orgGroups;
        if (userOrgGroups.length > 0) {
            if (superManager) {
                return query;
            } else if (manager) {
                query = query.where('claimantInfo.office', 'in', userOrgGroups);
            } else if (adjustor) {
              query = query.where('claimantInfo.office', 'in', userOrgGroups)
                           .where('uid', '==', userUid);
            } else {
              return query;
            }
        }
        return query;
      }

      // filter under review items based on user role
      queryUnderReviewByUserRole(query: firebase.firestore.Query, userUid: string, userRole: UserRoles) {
        const superManager = this.hasAnyUserRoles(userRole, ['superManager']);
        const manager = this.hasAnyUserRoles(userRole, ['manager']);
        const adjustor = this.hasAnyUserRoles(userRole, ['adjustor']);
        if (superManager) {
          return query;
        } else if (manager) {
            query = query.where('reviewProcessStartedBy', '==', userUid);
        } else if (adjustor) {
            query = query.where('uid', '==', userUid);
        } else {
          return query;
        }
        return query;
      }

      // filter reviewlist history items based on user role
      queryReviewDoneByUserRole(query: firebase.firestore.Query, userUid: string, userRole: UserRoles) {
        const superManager = this.hasAnyUserRoles(userRole, ['superManager']);
        const manager = this.hasAnyUserRoles(userRole, ['manager']);
        const adjustor = this.hasAnyUserRoles(userRole, ['adjustor']);
        if (superManager) {
          return query;
        } else if (manager) {
          return query;
        } else if (adjustor) {
          query = query.where('verifiedBy', '==', userUid);
          return query;
        } else {
          return query;
        }
        return query;
      }

      // filter auditlist items based on user role
      queryAuditlistByUserRole(query: firebase.firestore.Query, userUid: string, userRole: UserRoles, orgGroups: Array<string>) {
        const superManager = this.hasAnyUserRoles(userRole, ['superManager']);
        const manager = this.hasAnyUserRoles(userRole, ['manager']);
        const adjustor = this.hasAnyUserRoles(userRole, ['adjustor']);
        const userOrgGroups = orgGroups;
        if (superManager) {
          return query;
        } else if (manager) {
          query = query.where('office', 'in', userOrgGroups);
          return query;
        } else if (adjustor) {
          query = query.where('verifiedBy', '==', userUid);
          return query;
        } else {
          return query;
        }
        return query;
      }
    
      createDataStudioParameters(parameterNames, report: ReportItem) {
        const params = {};
        const valueTemplate = 'include%EE%80%800%EE%80%80IN%EE%80%80<parameterValue>';
        if (report.claimantInfo.injuryCode) {
          params[parameterNames.injuryCode] = valueTemplate.replace('<parameterValue>', this.getOriginalInjuryCode(report.claimantInfo.injuryCode));
        }
        if (report.claimantInfo.lawsuitStatus) {
          params[parameterNames.lawsuitStatus] = valueTemplate.replace('<parameterValue>', this.capitalize(report.claimantInfo.lawsuitStatus));
        }
        if (report.claimantInfo.lossCounty) {
          params[parameterNames.lossCounty] = valueTemplate.replace('<parameterValue>', this.capitalizeAll(report.claimantInfo.lossCounty));
          params[parameterNames.lossCounty] = params[parameterNames.lossCounty].replace('Miami Dade', 'Miami-Dade');
        }
        if (report.claimantInfo.lossState) {
          params[parameterNames.lossState] = valueTemplate.replace('<parameterValue>', this.convertToStateFullName(report.claimantInfo.lossState));
        }
        return params;
      }

      createDataStudioParametersClaimantOnly(parameterNames, report: ReportItem) {
        const params = {};
        const valueTemplate = 'include%EE%80%800%EE%80%80IN%EE%80%80<parameterValue>';
        if (report.claimantInfo.claim) {
          params[parameterNames.claim] = valueTemplate.replace('<parameterValue>', report.claimantInfo.claim);
        }
        return params;
      }

      generateHistoricalAnalysisParameters(report: ReportItem, alias?: string): string {
        const parameterNames = {
          claim: 'df152',
          injuryCode: 'df51',
          lawsuitStatus: 'df85',
          lossCity: '',
          lossCounty: 'df83',
          lossState: 'df194',
        }
        let params = {}; 
        if (alias === 'marlin') {
          params = this.createDataStudioParametersClaimantOnly(parameterNames, report);
        } else {
          params = this.createDataStudioParameters(parameterNames, report);
        }
        const paramsAsString = JSON.stringify(params);
        const encodedParams = encodeURIComponent(paramsAsString);
        return encodedParams;
      }

      toTitleCase(textInput) {
        if (!textInput) return textInput;
        return textInput
          .split(' ')
          .map(word => {
            if (word.length === 0) return word;
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
      }

      convertToStringArray(items: Array<any>) {
        return items.map(item => item.toString());
      }

      // return [[...],[...]] each inner array has 10 items
      splitItemsIntoBatches(itemList: Array<any>, eachBatch: number = 10) {
        const batches: Array<any[]> = [];
        const itemsLength = itemList.length;
        const maxItems = eachBatch;
        for (let i = 0; i < itemsLength; i += maxItems) {
            batches.push(itemList.slice(i, i + maxItems));
        }
        return batches;
      }

      parseQuestionIdAndAnswerIndexes(checklist: ChecklistItem[]) {
        const results = [];
        checklist.forEach(item => {
          const questionId = item.id;
          const answerIndexes = item.answers.map(answer => item.options.findIndex(option => option === answer));
          const answerText = '';
          results.push({questionId: questionId, answerIndexes: answerIndexes, answerText: answerText});
        });
        return results;
      }
}

